/**
 * 파일명 : hom0004m.jsx
 * 설명 : 이메일무단수집거부
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *     
 */

/************************************************
 * Import 영역  
 ************************************************/
import "../../css/tos.css";  // css

export default function Hom0004m() {

    return (
        <div className="hom0004m">
            <div className="rule" id="rule_top" style={{height:"805px"}}>
                <div className="rule_header">
                    <h1>이메일주소 무단 수집 거부</h1>
                </div>
                <div className="rule_body" >
                    <p className="txt_infobox">
                        회사의 이메일주소 무단 수집 거부 방침은 다음과 같은 내용을 담고 있습니다.
                    </p>
                    <dl className="text">
                        <dt>1.<span className="txt_header">총칙</span></dt>
                        <dd>
                            <br/><br/>
                            우리 "(주)위드정보" 회원에게 무차별적으로 보내지는 타사의 메일을 차단하기 위해, 본 웹사이트에 게시된 이메일 주소가
                            전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시
                            정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.<br/><br/>

                            게시일 2021년 01월 01일<br/><br/>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}