/**
 * 파일명 : hom0001p.jsx
 * 설명 : HEADER MENU
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2024-05-23   기라성     최초작성 합병공고문 팝업
 * 1.1       2024-07-01   기라성     합병보고공고로 수정    
 */

/************************************************
 * Import 영역  
 ************************************************/
import { Modal, Typography } from 'antd';
import with_logo_2 from "../../images/with_ci/with_logo_2.png";

export default function Hom0001p({isOpen, onClose}) {

    return (
        <Modal
         open={isOpen}
         title={null}
         footer={null}
         closable={true}
         width={735}
         onCancel={onClose}
        >
       
        <img src={with_logo_2} style={{width: '30%'}}/>
        <Typography style={{textAlign : 'center', fontFamily : 'Nanum Gothic', fontWeight : 'bold', fontSize: '25px'}}>합 병 보 고 공 고 </Typography>
        <br/>
        <p style={{fontSize : '18px'}}>
        1. 주식회사 위드정보(이하 “위드정보“라 함)와 주식회사 투게더솔루션(이하 “투게더솔루션”이라 함)은 2024년 5 월 28 일자 개최한 각 회사의 임시주주총회에서 합병
        을 결의하고, 그 결과로 위드정보는 투게더솔루션을 합병하여 그 권리의무 일체를 승
        계하고 위드정보는 존속하며 투게더솔루션은 해산하기로 결의하였습니다. 
        <br/>
        <br/>
        2. 위 결의에 의하여 양 회사는 상법 소정의 합병절차를 완료하였으므로 상법 제526조
        제3항에 의하여 합병보고총회에 갈음하여 이사회 결의와 이 공고로서 합병보고를 대체
        하기로 결의하였으므로, 이에 합병완료사실을 각 주주들께 공고로서 보고합니다. 
        <br/>
        <br/>
        </p>
        <p style={{fontSize : '18px', textAlign : 'center'}}>
        2024년  7 월 2 일  
        <br/><br/>
        <font style={{fontSize : '18px', textAlign : 'center', fontWeight : 'bold'}}>
        주식회사 위드정보    
        </font>  
        <br/>
        서울특별시 영등포구 양평로22길 21, 806호<br/>
        (양평동5가, 선유도코오롱디지털타워)<br/>
        대표이사   장 인 철
        <br/>
        <br/>
        <font style={{fontSize : '18px', textAlign : 'center', fontWeight : 'bold'}}>
        주식회사 투게더솔루션    
        </font>  
        <br/>
        서울특별시 영등포구 양평로22길 21, 805호<br/>
        (양평동5가, 선유도코오롱디지털타워)<br/>
        대표이사   최 희 억
        </p>
        <p style={{fontSize : '18px', textAlign : 'center'}}>
        위와 같이 2024년 7월 2일 회사 홈페이지(http://www.withinfo.co.kr)에 게재하여
        <br/>공고하였습니다.
        </p>
        <p style={{fontSize : '18px', textAlign : 'center'}}>
        주식회사 위드정보  
        <br/>
        대표이사 장 인 철 
        </p>  
        
        </Modal>
    )
}