// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/* CSS Document */

.pagingBtn[aria-current]{
    background-color: rgba(166, 213, 255, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/css/pagination.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB,iBAAiB;;AAEjB;IACI,0CAA0C;AAC9C","sourcesContent":["@charset \"utf-8\";\n/* CSS Document */\n\n.pagingBtn[aria-current]{\n    background-color: rgba(166, 213, 255, 0.7);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
