/**
 * 파일명 : hom0203m.jsx
 * 설명 : 고객사/협력사
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-06-27             최초작성
 *     
 */

/************************************************
 * Import 영역  
 ************************************************/
import "../../css/body.css";  // css

export default function Hom0203m() {

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0203m_W">
                    <div className="title">
                        <h3>&nbsp;고객사/협력사</h3>
                    </div>
                    <div className="body">
                        <div className="img_area">
                            <img src={require("../../images/with_client.jpg")} />
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0203m_M">
                    <div className="title">
                        <h3>&nbsp;고객사/협력사</h3>
                    </div>
                    <div className="body">
                        <div className="img_area">
                            <img src={require("../../images/with_client.jpg")} />
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}