/**
 * 파일명 : RouterComponent.jsx
 * 설명 : 화면 이동
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import React from 'react';
import {BrowserRouter, Route, Switch, Link, Routes} from "react-router-dom";
import ScrollTop from './ScrollTop';
import Hom0001m from "../pages/hom/hom0001m.jsx";
import Hom0002m from "../pages/hom/hom0002m.jsx";
import Hom0003m from "../pages/hom/hom0003m.jsx";
import Hom0004m from "../pages/hom/hom0004m.jsx";
import Hom0005m from "../pages/hom/hom0005m.jsx";
import Hom0006m from "../pages/hom/hom0006m.jsx";
import Hom0101m from "../pages/hom/hom0101m.jsx";
import Hom0102m from "../pages/hom/hom0102m.jsx";
import Hom0103m from "../pages/hom/hom0103m.jsx";
import Hom0201m from "../pages/hom/hom0201m.jsx";
import Hom0202m from "../pages/hom/hom0202m.jsx";
import Hom0203m from "../pages/hom/hom0203m.jsx";
import Hom0301m from "../pages/hom/hom0301m.jsx";
import Hom0302m from "../pages/hom/hom0302m.jsx";
import Hom0303m from "../pages/hom/hom0303m.jsx";
import Hom0304m from "../pages/hom/hom0304m.jsx";
import Hom0401m from "../pages/hom/hom0401m.jsx";
import Hom0402m from "../pages/hom/hom0402m.jsx";

const AppRouter = () => {
    return(
        <div>
            <BrowserRouter>
                <ScrollTop />
                <div style={{width:"100%"}}>
                    <Hom0005m />
                    <Routes>
                        <Route path="/"   exact Component={Hom0001m}/>
                        <Route path="/hom0002m" Component={Hom0002m}/>
                        <Route path="/hom0003m" Component={Hom0003m}/>
                        <Route path="/hom0004m" Component={Hom0004m}/>
                        <Route path="/hom0005m" Component={Hom0005m}/>
                        <Route path="/hom0006m" Component={Hom0006m}/>
                        <Route path="/hom0101m" Component={Hom0101m}/>
                        <Route path="/hom0102m" Component={Hom0102m}/>
                        <Route path="/hom0103m" Component={Hom0103m}/>
                        <Route path="/hom0201m" Component={Hom0201m}/>
                        <Route path="/hom0202m" Component={Hom0202m}/>
                        <Route path="/hom0203m" Component={Hom0203m}/>
                        <Route path="/hom0301m" Component={Hom0301m}/>
                        <Route path="/hom0302m" Component={Hom0302m}/>
                        <Route path="/hom0303m" Component={Hom0303m}/>
                        <Route path="/hom0304m" Component={Hom0304m}/>
                        <Route path="/hom0401m" Component={Hom0401m}/>
                        <Route path="/hom0402m" Component={Hom0402m}/>
                    </Routes>
                    <Hom0006m />
                </div>
            </BrowserRouter>
        </div>
    );
};

export default AppRouter;