/**
 * 파일명 : hom0301m.jsx
 * 설명 : 회사소식
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           BoardController 회사소식 목록 정보 조회 
 */
  
/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import { Input } from 'antd';
import "../../css/body.css";  // css
import CompanyBoard from "../../components/CompanyBoard.jsx";  // Board MAP
import Pagination from "../../components/Pagination.jsx";      // Pagination

export default function Hom0301m() {

    // 회사소식 목록 조회
    const [companyBoardInfo, setCompanyBoardInfo] = useState([]);
    useEffect(() => {
        fetch("/selectBoard"
        , {
            method: "POST"
          , headers: {'Content-type': 'application/json'}
          , body: JSON.stringify({
                                    instCd: '002'
                                  , boardDcd: '1'
                                  , sourceDcd: '05'
                                  , condition: ''
                                })
        })
        .then((response) => response.json())
        .then(data => {setCompanyBoardInfo(data)});
    }, []);
    
    const [limit, setLimit] = useState(3);        // 한 페이지에 보여줄 데이터의 개수
    const [page, setPage] = useState(1);          // 페이지 초기 값은 1페이지
    const [blockNum, setBlockNum] = useState(0);  // 한 페이지에 보여 줄 페이지네이션의 개수를 block으로 지정하는 state. 초기 값은 0

    const offset = (page - 1) * limit;

    const { Search } = Input;
    function onSearch(value)
    {
        fetch("/selectBoard"
        , {
            method: "POST"
          , headers: {'Content-type': 'application/json'}
          , body: JSON.stringify({
                                    instCd: '002'
                                  , boardDcd: '1'
                                  , sourceDcd: '05'
                                  , condition: value
                                })
        })
        .then((response) => response.json())
        .then(data => {setCompanyBoardInfo(data)});
    }

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0301m_W">
                    <div className="title">
                        <h3>&nbsp;회사소식</h3>
                    </div>
                    <div className="body">
                        <div className="contents">
                            <div className="board_top">
                                <div>
                                    <p>총 <span>{companyBoardInfo.length}</span>건</p>
                                </div>                        
                                <div className="search">
                                    <Search placeholder="input search text" onSearch={onSearch} style={{width:"200px"}} />
                                </div>
                            </div>
                            <div className="board">
                                <table>
                                    <tbody>
                                        { companyBoardInfo && companyBoardInfo.slice(offset, offset + limit).map( companyBoard => <CompanyBoard key={companyBoard.id} CompanyBoard={companyBoard} page="/hom0302m" />) }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <Pagination
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    blockNum={blockNum}
                                    setBlockNum={setBlockNum}
                                    counts={companyBoardInfo.length}
                                    pageLimit="10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0301m_M">
                    <div className="title">
                        <h3>&nbsp;회사소식</h3>
                    </div>
                    <div className="body">
                        <div className="contents">
                            <div className="board_top">
                                <div style={{paddingLeft:"5px"}}>
                                    <p>총 <span>{companyBoardInfo.length}</span>건</p>
                                </div>                        
                                <div className="search">
                                    <Search placeholder="input search text" onSearch={onSearch} style={{width:"200px"}} />
                                </div>
                            </div>
                            <div className="board">
                                <table>
                                    <tbody>
                                        { companyBoardInfo && companyBoardInfo.slice(offset, offset + limit).map( companyBoard => <CompanyBoard key={companyBoard.id} CompanyBoard={companyBoard} page="/hom0302m" />) }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <Pagination
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    blockNum={blockNum}
                                    setBlockNum={setBlockNum}
                                    counts={companyBoardInfo.length}
                                    pageLimit="5"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}