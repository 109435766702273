/**
 * 파일명 : Accordion.jsx
 * 설명 : 햄버거메뉴 클릭시 아코디언메뉴로 구성
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 1.1       2024-07-01   기라성     도메인 수정
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../css/accordion.css";

function Accordion(props) {
  const { summary, children } = props;
  const contentRef = useRef();
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(contentRef?.current?.clientHeight ?? 0);
  }, []);

  return (
    <>
      <div className={"accordion"}>
        {children ? 
          <details>
            <summary>
              <span>{summary}</span>
              <div className={children ? "arrow" : ""}></div>
            </summary>
          </details>
        :
          <div className="noDetails">
                <span><Link to = "http://withinfo.co.kr:3000/" target="_blank" rel="noopener noreferrer">{summary}</Link></span>
          </div>
        }
        <div className={"content-wrapper"}>
          <div className={children ? "content" : "noContent"} ref={contentRef}>
            {children}
          </div>
        </div>

      </div>
    </>
  );
}

export default Accordion;