/**
 * 파일명 : hom0101m.jsx
 * 설명 : 회사개요
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           businessController 호출 - 회사 정보 조회
 * 1.1       2024-07-01   기라성     조직도 이미지 수정
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import "../../css/body.css";  // css

export default function Hom0101m() {
    
    // 회사정보 조회
    const [companyInfo, setCompanyInfo] = useState("");
    useEffect(() => {
        fetch("/selectCompany")
        .then((response) => response.json())
        .then(data => {setCompanyInfo(data)});
    }, []);

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0101m_W">
                    <div className="title_1">
                        {/* <h3>&nbsp;회사소개</h3> */}
                    </div>
                    <div className="body">
                        <div className="greeting">
                            {/* <div className="greeting_img_area">
                                <img src={require("../../images/with_slogan.png")} alt="인사말"></img>
                            </div> */}
                            <div className="greeting_txt_area">
                                <dl>
                                    <dd className="greeting_txt">
                                        <h2 style={{borderLeft:"8px solid rgb(14, 168, 212)", fontSize:"28px"}}>&nbsp;&nbsp;위드정보,{/*<br/>*/}&nbsp;&nbsp;함께 성장하고 함께 나누는 따뜻한 IT Partner</h2>
                                        <h3 style={{color : "#003b83"}}>안녕하십니까? 고객의 성공을 보장하고 직원의 성장을 추구하는 금융 IT 회사 위드정보입니다.</h3>
                                        {/* 변화를 두려워하지 않고,<br/>
                                        날마다 새롭게 쉼없이 진보하고 있는 위드정보 입니다.<br/><br/> */}
                                        저희 위드정보는 금융IT 전문회사로서 IT컨설팅, SI/SM 등의 사업을 추진하고 있으며, 20년 이상의 풍부한 경험과 전문지식을 보유한 핵심 인력을 중심으로 수직 성장하는 <br />여신/보증/리스크 전문 강소 기업 입니다.<br/><br />
                                        {/* 투게더 솔루션은 고객과 같은 목표를 가지고<br/>
                                        <span style={{backgroundColor:"rgba(227, 229, 250, 1)", fontWeight:"bold"}}>최고의 금융 IT 서비스 제공</span>을 위하여 끊임없이 노력하고 있습니다.<br/><br/>
                                        직원과 고객이 함께 함으로써 이룩한 성장을<br/>
                                        소중히 여기는 기업이 될 수 있도록 노력하겠습니다.<br/><br/> */}
                                        현장에서 수많은 프로젝트를 성공적으로 수행한 금융 IT 전문가를 중심으로 여신/보증/리스크 관리 시스템 구축을 전문 영역으로 하여 고객에게 최상의 서비스를 제공하기 위하여 노력하고 있습니다. <br /><br />
                                        위드정보는 고객의 비즈니스의 성공을 위해 헌신의 노력을 다하고, 임직원은 금융 IT 전문가로서의 자부심을 가지고 일에 임함으로써 금융산업 발전에 기여하는 기업이 되고자 합니다. <br /><br />
                                        위드정보는 고객의 성공과 직원의 성장을 함께 도모하며, 신뢰와 배려를 바탕으로 고객에게 최상의 가치를 제공하고자 합니다. <br /><br />
                                        저희 임직원 모두 최선을 다하겠습니다. 많은 관심과 격려 부탁드립니다. <br /><br />
                                        감사합니다.
                                    </dd>
                                    {/* <dt className="greeting_txt_big">
                                        <b>To gather our potential, We are TOGETHER !!</b>
                                    </dt> */}
                                </dl>
                            </div>
                        </div>
                        <div className="title_2">
                            <h3>&nbsp;일반현황</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>회사명</th>
                                        <td>{companyInfo.instNm}</td>
                                        <th>대표자명</th>
                                        <td>{companyInfo.repreNm}</td>
                                    </tr>
                                    <tr>
                                        <th>기술용역등록분야</th>
                                        <td colspan="3"><b>업태 : </b>서비스&nbsp;/&nbsp;<b>종목 : </b>{companyInfo.bizTpNm}</td>
                                    </tr>
                                    <tr>
                                        <th>주소</th>
                                        <td colspan="3">{companyInfo.address}</td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td><b>전화번호 : </b>{companyInfo.poneNumber}</td>
                                        <td colspan="2"><b>팩스 : </b>{companyInfo.faxNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>회사설립년도</th>
                                        <td colspan="3">{companyInfo.fndnDt}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="title_3">
                            <h3>&nbsp;조직도</h3>
                            <div className="title_3_img_area">
                                <img src={require("../../images/with_orgChart2.png")} alt="조직도"></img>
                            </div>
                        </div>
                        <div className="title_4">
                            <h3>&nbsp;회사비전</h3>
                            <div className="title_4_img_area">
                                <img src={require("../../images/with_vision.png")} alt="회사비전"></img>
                            </div>
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0101m_M">
                    <div className="title_1">
                        {/* <h3>&nbsp;회사소개</h3> */}
                    </div>
                    <div className="body">
                        <div className="greeting">
                            {/* <div className="greeting_img_area">
                                <img src={require("../../images/with_slogan.png")} alt="인사말"></img>
                            </div> */}
                            <div className="greeting_txt_area">
                                <dl>
                                    <dd className="greeting_txt">
                                        <h2 style={{borderLeft:"8px solid rgb(14, 168, 212)"}}>&nbsp;&nbsp;위드정보,<br/>&nbsp;&nbsp;함께 성장하고 함께 나누는 따뜻한 IT Partner</h2>
                                        <h3 style={{color : "#003b83"}}>안녕하십니까? 고객의 성공을 보장하고 직원의 성장을 추구하는 금융 IT 회사 위드정보입니다.</h3>
                                        {/* 변화를 두려워하지 않고,<br/>
                                        날마다 새롭게 쉼없이 진보하고 있는 위드정보 입니다.<br/><br/> */}
                                        저희 위드정보는 금융IT 전문회사로서 IT컨설팅, SI/SM 등의 사업을 추진하고 있으며, 20년 이상의 풍부한 경험과 전문지식을 보유한 핵심 인력을 중심으로 수직 성장하는 <br />여신/보증/리스크 전문 강소 기업 입니다.<br/><br />
                                        {/* 투게더 솔루션은 고객과 같은 목표를 가지고<br/>
                                        <span style={{backgroundColor:"rgba(227, 229, 250, 1)", fontWeight:"bold"}}>최고의 금융 IT 서비스 제공</span>을 위하여 끊임없이 노력하고 있습니다.<br/><br/>
                                        직원과 고객이 함께 함으로써 이룩한 성장을<br/>
                                        소중히 여기는 기업이 될 수 있도록 노력하겠습니다.<br/><br/> */}
                                        현장에서 수많은 프로젝트를 성공적으로 수행한 금융 IT 전문가를 중심으로 여신/보증/리스크 관리 시스템 구축을 전문 영역으로 하여 고객에게 최상의 서비스를 제공하기 위하여 노력하고 있습니다. <br /><br />
                                        위드정보는 고객의 비즈니스의 성공을 위해 헌신의 노력을 다하고, 임직원은 금융 IT 전문가로서의 자부심을 가지고 일에 임함으로써 금융산업 발전에 기여하는 기업이 되고자 합니다. <br /><br />
                                        위드정보는 고객의 성공과 직원의 성장을 함께 도모하며, 신뢰와 배려를 바탕으로 고객에게 최상의 가치를 제공하고자 합니다. <br /><br />
                                        저희 임직원 모두 최선을 다하겠습니다. 많은 관심과 격려 부탁드립니다. <br /><br />
                                        감사합니다.
                                    </dd>
                                    {/* <dt className="greeting_txt_big">
                                        <b>To gather our potential, We are TOGETHER !!</b>
                                    </dt> */}
                                </dl>
                            </div>
                        </div>
                        <div className="title_2">
                            <h3>&nbsp;일반현황</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>회사명</th>
                                        <td>{companyInfo.instNm}</td>
                                        <th>대표자명</th>
                                        <td>{companyInfo.repreNm}</td>
                                    </tr>
                                    <tr>
                                        <th>기술용역등록분야</th>
                                        <td colspan="3"><b>업태 : </b>서비스&nbsp;/&nbsp;<b>종목 : </b>{companyInfo.bizTpNm}</td>
                                    </tr>
                                    <tr>
                                        <th>주소</th>
                                        <td colspan="3">{companyInfo.address}</td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td><b>전화번호 : </b>{companyInfo.poneNumber}</td>
                                        <td colspan="2"><b>팩스 : </b>{companyInfo.faxNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>회사설립년도</th>
                                        <td colspan="3">{companyInfo.fndnDt}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="title_3">
                            <h3>&nbsp;조직도</h3>
                            <div className="title_3_img_area">
                                <img src={require("../../images/with_orgChart2.png")} alt="조직도"></img>
                            </div>
                        </div>
                        <div className="title_4">
                            <h3>&nbsp;회사비전</h3>
                            <div className="title_4_img_area">
                                <img src={require("../../images/with_vision.png")} alt="회사비전"></img>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}