/**
 * 파일명 : hom0103m.jsx
 * 설명 : 사업분야
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *     
 */

/************************************************
 * Import 영역  
 ************************************************/
import "../../css/body.css";  // css

export default function Hom0103m() {

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0103m_W">
                    <div className="title">
                        <h3>&nbsp;사업분야</h3>
                    </div>
                    <div className="body">
                        <p>
                            ㈜ 위드정보는 <b>여신, 신용리스크, 보증업무와 관련된 금융기관의 업무시스템 구축</b>을 전문으로 하는 금융IT전문업체입니다. <br/> 금융업권에서 여신 및 보증 업무를 수행하기 위하여 필요한 여신상담 및 신청단계에서 심사/승인, 계정처리, 사후관리에 이르는 모든 업무 시스템의 분석/설계 및 구축 업무를 수행하고 있습니다.
                        </p>  
                        <div className="img_area">
                            <img src={require("../../images/with_businessAreas.png")} alt="사업분야"></img>
                        </div>
                        <div className="box_area">
                            <table>
                                <tr>
                                    <th>여신심사시스템 구축</th>
                                    <td>여신 실행 이전에 여신 대상고객에 대한 상담진행, 차주의 신용위험 판단, 담보를 통한 신용보강 및 전결권 결정 및 전결권에 따른 심사 진행 프로세스를 관리하는 시스템 구축 서비스 제공</td>
                                </tr>
                                <tr>
                                    <th>여신계정계(실행 및 상환)시스템 구축</th>
                                    <td>여신승인 건에 대한 한도약정, 여신실행, 이자계산, 여신상환, 여신업무 결산 처리를 관리하는 IT서비스 제공</td>
                                </tr>
                                <tr>
                                    <th>여신사후관리시스템 구축</th>
                                    <td>여신실행 이후 본격적인 부실화 이전에 사전적 사후관리를 담당하는 조기경보/론리뷰시스템, 부실여신에 대한 사후관리(일반사후관리, 법적절차관리, 채권조정, 자산매각, 대손상각/특수채권관리)시스템 구축 서비스 제공</td>
                                </tr>
                                <tr>
                                    <th>컴플라이언스시스템 구축</th>
                                    <td>바젤II, 바젤III와 같은 감독당국의 신용리스크 규제에 대응하는 시스템 및 국제회계기준도입 및 변경에 대응하는 IFRS시스템 구축 서비스 제공</td>
                                </tr>
                                <tr>
                                    <th>보증시스템 구축</th>
                                    <td>개인에 대한 전세보증금반환보증, 주택구입 및 임차자금보증 등과 기업에 대한 주택분양보증, 주택사업금융보증 등의 업무를 처리하는 시스템 구축 서비스 제공</td>
                                </tr>
                                <tr>
                                    <th>여신 및 보증 SM서비스</th>
                                    <td>국내 금융기관의 여신 및 보증업무 시스템에 대한 시스템유지보수(system management) 서비스 제공</td>
                                </tr>
                                <tr>
                                    <th>여신 및 보증 PI컨설팅</th>
                                    <td>여신 실행 이전에 여신 대상고객에 대한 상담진행, 차주의 신용위험 판단, 담보를 통한 신용보강 및 전결권 결정 및 전결권에 따른 심사 진행 프로세스를 관리하는 시스템 구축 서비스 제공</td>
                                </tr>
                            </table>
                            {/* <ul>
                                <li>금융업무 및 IT<br />전문인력 보유</li>
                                <li>다양한 금융기관<br />사업수행 경험</li>
                                <li>고객과 함께하는<br />사업수행 전략</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0103_M">
                    <div className="title">
                        <h3>&nbsp;사업분야</h3>
                    </div>
                    <div className="body">
                        <p className="txt_area">
                            ㈜ 위드정보는 <b>여신, 신용리스크, 보증업무와 관련된 금융기관의 업무시스템 구축</b>을 전문으로 하는 금융IT전문업체입니다. <br/> 금융업권에서 여신 및 보증 업무를 수행하기 위하여 필요한 여신상담 및 신청단계에서 심사/승인, 계정처리, 사후관리에 이르는 모든 업무 시스템의 분석/설계 및 구축 업무를 수행하고 있습니다.
                        </p>
                        <div className="img_area">
                            <img src={require("../../images/with_businessAreas.png")} alt="사업분야"></img>
                        </div>
                        <div className="box_area">                           
                            <ul>
                                <li>여신심사시스템 구축</li>
                                <p>여신 실행 이전에 여신 대상고객에 대한 상담진행, 차주의 신용위험 판단, 담보를 통한 신용보강 및 전결권 결정 및 전결권에 따른 심사 진행 프로세스를 관리하는 시스템 구축 서비스 제공</p>
                                <li>여신계정계(실행 및 상환)시스템 구축</li>
                                <p>여신승인 건에 대한 한도약정, 여신실행, 이자계산, 여신상환, 여신업무 결산 처리를 관리하는 IT서비스 제공</p>
                                <li>여신사후관리시스템 구축</li>
                                <p>여신실행 이후 본격적인 부실화 이전에 사전적 사후관리를 담당하는 조기경보/론리뷰시스템, 부실여신에 대한 사후관리(일반사후관리, 법적절차관리, 채권조정, 자산매각, 대손상각/특수채권관리)시스템 구축 서비스 제공</p>
                                <li>컴플라이언스시스템 구축</li>
                                <p>바젤II, 바젤III와 같은 감독당국의 신용리스크 규제에 대응하는 시스템 및 국제회계기준도입 및 변경에 대응하는 IFRS시스템 구축 서비스 제공</p>
                                <li>보증시스템 구축</li>
                                <p>개인에 대한 전세보증금반환보증, 주택구입 및 임차자금보증 등과 기업에 대한 주택분양보증, 주택사업금융보증 등의 업무를 처리하는 시스템 구축 서비스 제공</p>
                                <li>여신 및 보증 SM서비스</li>
                                <p>국내 금융기관의 여신 및 보증업무 시스템에 대한 시스템유지보수(system management) 서비스 제공</p>
                                <li>여신 및 보증 PI컨설팅</li>
                                <p>여신 실행 이전에 여신 대상고객에 대한 상담진행, 차주의 신용위험 판단, 담보를 통한 신용보강 및 전결권 결정 및 전결권에 따른 심사 진행 프로세스를 관리하는 시스템 구축 서비스 제공</p>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}