/**
 * 파일명 : hom0401m.jsx
 * 설명 : 채용정보
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성 
 */

/************************************************
 * Import 영역  
 ************************************************/
// import { useState, useEffect } from 'react';
import "../../css/body.css";  // css

export default function Hom0401m() {

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0401m_W">
                    <div className="title">
                        <h3>&nbsp;채용정보</h3>
                    </div>
                    <div className="body">
                        <div className="img_area">
                            <img src={require("../../images/with_jobOpening.jpg")} />
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0401m_M">
                    <div className="title">
                        <h3>&nbsp;채용정보</h3>
                    </div>
                    <div className="body">
                        <div className="img_area">
                            <img src={require("../../images/with_jobOpening.jpg")} />
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}