/**
 * 파일명 : ImgUrl.jsx
 * 설명 : 사업실적, 회사소개 게시판 이미지 호출
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useEffect, useState } from 'react';
import * as imageUtil from "../utils/imageUtil";
import { Image } from 'antd';

// deconstructed props
function ImgUrl({strBase64}) {

    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        // console.log("strBase64: " + strBase64);
        strBase64 && setImageUrl(imageUtil.fn_getImageUrl(strBase64));
    }, []);    

    return (
        <div style={{display : "flex", justifyContent : "center"}}>
            <Image src={imageUrl} width="95%" height="95%" style={{objectFit: "contain"}} />
        </div>
    )
}
export default ImgUrl;
