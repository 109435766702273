/**
 * 파일명 : hom0201m.jsx
 * 설명 : 사업실적
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           projectController 사업실적 목록 정보 조회
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import { Input } from 'antd';
import "../../css/body.css";  // css
import ProjectBoard from "../../components/ProjectBoard.jsx";   // Project MAP
    import Pagination from "../../components/Pagination.jsx";  // Pagination

export default function Hom0201m() {
    
    // 사업실적 목록 조회
    const [projectBoardInfo, setProjectBoardInfo] = useState([]);
    useEffect(() => {
        fetch("/selectProject"
        , {
            method: "POST"
          , headers: {'Content-type': 'application/json'}
          , body: JSON.stringify({
                                       instCd: '002'
                                  , condition: ''
                                })
        })
        .then((response) => response.json())
        .then(data => {setProjectBoardInfo(data)});
    }, []);

    const [limit, setLimit] = useState(5);        // 한 페이지에 보여줄 데이터의 개수
    const [page, setPage] = useState(1);          // 페이지 초기 값은 1페이지
    const [blockNum, setBlockNum] = useState(0);  // 한 페이지에 보여 줄 페이지네이션의 개수를 block으로 지정하는 state. 초기 값은 0

    const offset = (page - 1) * limit;

    const { Search } = Input;
    function onSearch(value)
    {
        fetch("/selectProject"
        , {
            method: "POST"
          , headers: {'Content-type': 'application/json'}
          , body: JSON.stringify({
                                    instCd: '002'
                                  , condition: value
                                })
        })
        .then((response) => response.json())
        .then(data => {setProjectBoardInfo(data)});
    }

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0201m_W">
                    <div className="title">
                        <h3>&nbsp;사업실적</h3>
                    </div>
                    <div className="body">
                        <div className="contents">
                            <div className="board_top">
                                <div>
                                    <p>총 <span>{projectBoardInfo.length}</span>건</p>
                                </div>
                                <div className="search">                                
                                    <Search placeholder="input search text" onSearch={onSearch} style={{width:"200px"}} />
                                </div>
                            </div>
                            <div className="board">
                                <table>
                                    <tbody>
                                        { projectBoardInfo && projectBoardInfo.slice(offset, offset + limit).map( projectBoard => <ProjectBoard key={projectBoard.id} ProjectBoard={projectBoard} page="/hom0202m"/>) }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <div>
                                    <Pagination
                                        limit={limit}
                                        page={page}
                                        setPage={setPage}
                                        blockNum={blockNum}
                                        setBlockNum={setBlockNum}
                                        counts={projectBoardInfo.length}
                                        pageLimit="10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0201m_M">
                    <div className="title">
                        <h3>&nbsp;사업실적</h3>
                    </div>
                    <div className="body">
                        <div className="contents">
                            <div className="board_top">
                                <div style={{paddingLeft:"5px"}}>
                                    <p>총 <span>{projectBoardInfo.length}</span>건</p>
                                </div>   
                                <div className="search">                                
                                    <Search placeholder="input search text" onSearch={onSearch} style={{width:"200px"}} />
                                </div>
                            </div>
                            <div className="board">
                                <table>
                                    <tbody>
                                        { projectBoardInfo && projectBoardInfo.slice(offset, offset + limit).map( projectBoard => <ProjectBoard key={projectBoard.id} ProjectBoard={projectBoard} page="/hom0202m"/>) }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <div>
                                    <Pagination
                                        limit={limit}
                                        page={page}
                                        setPage={setPage}
                                        blockNum={blockNum}
                                        setBlockNum={setBlockNum}
                                        counts={projectBoardInfo.length}
                                        pageLimit="5"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}
