/**
 * 파일명 : hom0001m.jsx
 * 설명 : 메인화면
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *                        메인화면 1
 * 1.1       2024-05-23   기라성     정해진 기간에 합병공고문 팝업 추가
 * 1.2       2024-07-01   기라성     합병보고공문 정해진 기간으로 띄우도록 수정
 */

/************************************************
 * Import 영역  
 ************************************************/
import {Layout} from 'antd';
import With_main_3 from "../../images/with_main.png";
import Hom0001p from "./hom0001p";
import {useState } from 'react';

function isNoticed() {
    const startDate = new Date("2024-07-01 00:00:00");
    const endDate = new Date("2024-07-03 00:00:00")
    
    let isNoticed = false;
    if(Date.now() >= startDate && Date.now() <= endDate){
        isNoticed = true
    }
    return isNoticed;
  }

export default function Hom0001m() {
    const [notice, setNotice] = useState(isNoticed); 
    
    
    const noticeFormClose = () => {
        setNotice(false);
    }
    return (
        <div>
            <Layout>
                <div style={{marginTop:"63px"}}>
                    <div style={{marginBottom:"-4px"}}>
                        <img src={With_main_3} style={{width:"100vW"}} />
                    </div>
                </div>
            </Layout>
            {notice? <Hom0001p isOpen={notice} onClose={noticeFormClose}/> : null}

        </div>
    )
 }