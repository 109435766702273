/**
 * 파일명 : hom0006m.jsx
 * 설명 : FOOTER MENU
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *     
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Layout } from 'antd';
import "../../css/footer.css";

export default function Hom0006m() {
    
    // 회사정보 조회
    const [companyInfo, setCompanyInfo] = useState("");
    useEffect(() => {
        fetch("/selectCompany")
        .then((response) => response.json())
        .then(data => {setCompanyInfo(data)});
    }, []);

    return (
        <Layout className="footer">
            <div className="bottomNav">
                <div className="naviBox">
                    <ul className="naviTos">
                        <li>
                            <Link to="/hom0002m" className="hyperlink" style={{marginRight:"10px"}}>
                                이용약관
                            </Link>
                        </li>
                        <li>|</li>
                        <li>
                            <Link to="/hom0003m" className="hyperlink" style={{marginLeft:"10px", marginRight:"10px"}}>
                                개인정보취급방침
                            </Link>
                        </li>
                        <li>|</li>
                        <li>
                            <Link to="/hom0004m" className="hyperlink" style={{marginLeft:"10px"}}>
                                이메일무단수집거부
                            </Link>
                        </li>
                    </ul>
                    <br/>
                    <ul className="naviInfo">
                        <li>
                            주소 : {companyInfo.address} &nbsp;&nbsp;&nbsp;&nbsp;
                        </li>
                        <li>
                            Tel : {companyInfo.poneNumber} &nbsp;&nbsp;|&nbsp;&nbsp; Fax : {companyInfo.faxNumber}
                        </li>
                    </ul>
                    <div className="copyright">
                        Copyright © 2020 {companyInfo.instNm}. All rights reserved.
                    </div>
                    <br/>
                </div>
                <div className="logo_img">
                    <a href="http://14.36.105.106:9530/" target="_blank">
                        <img src={require("../../images/with_ci/with_logo_1.jpg")}></img>
                    </a>
                </div>
            </div>
        </Layout>
    )
}
